import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Layout from '../components/layout';
import Seo from "../components/seo";
import LogoImage from '../images/thumnail_blog_default.png';
/////////////////////////////////////////////////////////////////////////////////////////
const BlogPage = (props) => {
  const FEATURED_POST_NUM = 4;
  //
  let ALL_POSTS = props.pageContext.posts;
  const POST_LENGTH = ALL_POSTS.length;
  // get sticky (featured) post
  let featuredPosts = ALL_POSTS.filter(p => p.isSticky);
  // no featured posts, get latest posts
  if (featuredPosts.length === 0) {
    featuredPosts = ALL_POSTS.slice(0, FEATURED_POST_NUM);
  } else {
    if (featuredPosts.length < FEATURED_POST_NUM) {
      for (let i = 0; i < ALL_POSTS.length; i++) {
        const p = ALL_POSTS[i];
        const thePost = featuredPosts.find(x => x.id === p.id);
        if (!thePost) {
          featuredPosts.push(thePost);
          if (featuredPosts.length >= FEATURED_POST_NUM) {
            break;
          }
        }
      }
    }
  }
  // feature ids
  const featuredIds = featuredPosts.map(p => p.id);
  // remove featured post
  ALL_POSTS = ALL_POSTS.filter(p => !featuredIds.includes(p.id));
  // get first featured news
  const F1 = featuredPosts.shift();
  // console.log(F1)
  // get category name
  let categories = new Set();
  ALL_POSTS.forEach(p => {
    p.categories.nodes.forEach(c => categories.add(c.name));
  });
  categories = Array.from(categories);
  categories.sort();
  //
  const DATA = [];
  for (let j = 0; j < categories.length; j++) {
    const name = categories[j];
    const record = { cat: name };
    const postArr = [];
    for (let i = 0; i < ALL_POSTS.length; i++) {
      if (ALL_POSTS[i].categories.nodes.some(thisCat => thisCat.name === name)) {
        postArr.push(ALL_POSTS[i]);
        // get 3
        if (postArr.length === 3) {
          break;
        }
      };

    }
    //
    record['posts'] = postArr;
    DATA.push(record);
  };
  //
  const seo = {};
  //
  return (
    <Layout>
      <Seo title={`Blog `} canonical={'/blog'} seo={seo} />
      <div class="main-bg">
        <div class="overlay"></div>
        <img src="/assets/images/blog.jpg" alt="Blog" />
        <h1>Blog</h1>
      </div>
      <section class="py-5">
        <div className="container px-0">
          <div>
            <div className="row">
              <div className="pb-60 mx-4">
                <h2 class="title mx-2">Featured News</h2>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <a href={"/blog/" + F1.slug}>
                      <div className="thumb">
                        <img src={F1.featuredImage?.node.mediaItemUrl} width="100%" />
                        <div class="hover-overlay" style={{ lineHeight: '400px' }}>Read more</div>
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-12 col-md-6 box">
                    <div className="text">
                      <h3 className="card-title">
                        <Link className="text-secondary" to={"/blog/" + F1.slug} style={{ textDecoration: 'none' }}>
                          {F1.title}
                        </Link>
                      </h3>
                      <h4>{ F1.seo.metaDesc || F1.seo.opengraphDescription || F1.seo.twitterDescription }</h4>
                      <div className="info">
                        <a className="category" href={"/blogs/" + F1.categories?.nodes[0]?.name.toLowerCase().replaceAll(' ', '-')}>{F1.categories?.nodes[0]?.name}</a>
                        <span className="date">{F1.date}</span>
                      </div></div>
                  </div>
                </div>
              </div></div>
            <div className="row pb-60 mx-4">
                {
                  featuredPosts.map((node, index) => (
                    <div className="col-md-4">
                      <div className="card" style={{ 'border': 'none', borderRadius: 'inherit' }}>
                        <a href={"/blog/" + node.slug}>
                          <div className="thumb">
                            {node.featuredImage?.node.localFile?.childImageSharp ?
                              <GatsbyImage
                                image={
                                  node.featuredImage?.node.localFile?.childImageSharp?.gatsbyImageData
                                }
                                alt="blog"
                              />
                              : node.featuredImage?.node.mediaItemUrl ? <img src={node.featuredImage?.node.mediaItemUrl} height="230" width="100%" />
                                : <img src={LogoImage} height="230" />
                            }
                            <div class="hover-overlay">Read more</div>
                          </div>
                        </a>
                        <div className="card-body px-0 pt-0">
                          <h5 className="card-title">
                            <Link className="text-secondary" to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                              {node.title}
                            </Link>
                          </h5>
                          <div className="info">
                            <a className="category" href={"/blogs/" + node.categories?.nodes[0]?.name.toLowerCase().replaceAll(' ', '-')}>{node.categories?.nodes[0]?.name}</a>
                            <span className="date">{node.date}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
          </div>
        </div>
      </section>
      <section class="py-5">
        <div className="container px-0">
          <div className="row">
            {
              DATA.map((data, index) => (
                <div className="pb-60 mx-4">
                  <h2 class="title mx-2">
                    <span>{data.cat}</span>
                    <a className="text-secondary" href={"/blogs/" + data.cat.toLowerCase().replaceAll(' ', '-')}>Show more</a>
                  </h2>
                  {
                    <div className={index % 2 === 0 ? 'bg-light' : ''}>
                      <div className="row">
                        {
                          data.posts.map((node, index) => (
                            <div className="col-md-4">
                              <div className="card" style={{ 'border': 'none', borderRadius: 'inherit' }}>
                                <a href={"/blog/" + node.slug}>
                                  <div className="thumb">
                                    {node.featuredImage?.node.localFile?.childImageSharp ?
                                      <GatsbyImage
                                        image={
                                          node.featuredImage?.node.localFile?.childImageSharp?.gatsbyImageData
                                        }
                                        alt="blog"
                                      />
                                      : node.featuredImage?.node.mediaItemUrl ? <img src={node.featuredImage?.node.mediaItemUrl} height="230" width="100%" />
                                        : <img src={LogoImage} height="230" />
                                    }
                                    <div class="hover-overlay">Read more</div>
                                  </div>
                                </a>
                                <div className="card-body px-0 pt-0">
                                  <h5 className="card-title">
                                    <Link className="text-secondary" to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                      {node.title}
                                    </Link>
                                  </h5>
                                  <div className="info">
                                    <a className="category" href={"/blogs/" + data.cat.toLowerCase().replaceAll(' ', '-')}>{data.cat}</a>
                                    <span className="date">{node.date}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  }
                </div>
              ))
            }
          </div>

        </div>
      </section>


      {/* </section> */}
    </Layout>
  )
}

// export const query = graphql`
//   query {
//     ALL_POSTS: allWpPost(
//       filter: {tags: {nodes: {elemMatch: {name: {ne: "Patent Review"}}}}}
//       sort: {order: DESC, fields: date}) {
//       nodes {
//         id
//         uri
//         title
//         slug
//         isSticky
//         date(formatString: "MMM DD")
//         seo {
//           metaDesc
//           opengraphDescription
//           twitterDescription
//         }
//         categories {
//           nodes {
//             name
//           }
//         }
//         tags {
//           nodes {
//             name
//           }
//         }
//         featuredImage {
//           node {
//             mediaItemUrl
//           }
//         }
//       }
//     }
//   }
// `

export default BlogPage;
